/**
 * fromStringToArr
 *
 * Parse and returns an array from an
 * array inside a String.
 *
 *    Regex Fragment  |  Meaning
 *    ==============================
 *      [       ]     |  Match characters that are...
 *       "            |  ... double-quote
 *        |           |  OR
 *         \[         |  ... opening bracket
 *           |        |  OR
 *            \]      |  ... closing bracket
 */

export function fromStringToArr(arrString) {
  let result = [];
  let arrOfStringValues = arrString.replace(/"|\[|\]/g, '').split(',');

  arrOfStringValues.map((strNumber) => result.push(+strNumber));

  return result;
}
