import { sendUtagEvent } from '../../core/analytics/utag_events'
import on from '../../utils/events_delegation'
import { getCleanedString } from '../../utils/utilities'

const BLOCK_SELECTOR = '.js-filters-sidebar'

export function initializeUtagEvents() {
  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} .utag-hotels-filters-continents`,
    handler() {
      let continent

      switch (element.getAttribute('data-root-destination')) {
        case 'destination-2':
          continent = 'europe'
          break
        case 'destination-3':
          continent = 'the americas'
          break
        case 'destination-95':
          continent = 'africa'
          break
        default:
          continent = 'undefined'
      }

      sendUtagEvent({
        data: {
          event_name: 'filters',
          event_cat: 'filters destinations',
          event_act: 'continents',
          event_lbl: continent,
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    },
  })

  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} input.utag-hotels-filters-stars`,
    handler() {
      const element = this

      if (!element.checked) {
        return
      }

      sendUtagEvent({
        data: {
          event_name: 'filters',
          event_cat: 'filters destinations',
          event_act: 'stars',
          event_lbl: getCleanedString(element.getAttribute('data-utag-stars')),
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    },
  })

  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} input.utag-hotels-filters-services`,
    handler() {
      const element = this

      if (!element.checked) {
        return
      }

      sendUtagEvent({
        data: {
          event_name: 'filters',
          event_cat: 'filters destinations',
          event_act: 'services',
          event_lbl: getCleanedString(element.getAttribute('data-utag-service')),
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    },
  })

  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} input.utag-hotels-filters-specilities`,
    handler() {
      const element = this

      if (!element.checked) {
        return
      }

      sendUtagEvent({
        data: {
          event_name: 'filters',
          event_cat: 'filters destinations',
          event_act: 'specialities',
          event_lbl: getCleanedString(element.getAttribute('data-utag-sepeciality')),
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    },
  })

  on({
    eventName: 'click',
    selector: `${BLOCK_SELECTOR} input.utag-hotels-filters-countries`,
    handler() {
      const element = this

      if (!element.checked) {
        return
      }

      sendUtagEvent({
        data: {
          event_name: 'filters',
          event_cat: 'filters destinations',
          event_act: 'countries',
          event_lbl: getCleanedString(element.getAttribute('data-utag-country') || ''),
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    },
  })
}
