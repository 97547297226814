import '../fastbooking_mobile_map/fastbooking_mobile_map.scss'

// TODO: PY05649-376 Add loading="lazy" to images in html inside swiper slides (Lazy module was removed from Swiper 9)
import { getCheckedFilters } from '../../blocks/hotels_info_with_filtering_packed/dependencies/filters'
import initTemplate from '../../vendors/template_engine/template_engine'

/* eslint-disable */

let $map = null
let hotels_map
let all_markers_array
let all_hotels_data = null
const defaults = {
  type: 'visible',
}

function initHotelsMapForFilerPage($this, options) {
  IB.hotels_data.check()
    ? initMap($this, options)
    : IB.hotels_data.get(function () {
        initMap($this, options)
      })
}

function initMap($this, options) {
  //if (document.querySelector('.loading-map')) document.querySelector('.loading-map').style.display = 'flex';
  if (!$this.length) return false
  const all_hotels_ids = []
  const hotels_data = IB.hotels_data.getData()
  const locations = {}
  const opt = $.extend({}, defaults, options)
  $map = $('#hotels-filter-map')
  all_hotels_data = []

  $('[data-block-comparator]').addClass('hidden current')

  if ($map.length === 0 || hotels_data === null) return false

  let getHotelsFilterableInfo
  if ($('.destination-index-container').length > 0) {
    getHotelsFilterableInfo = $('.destination-index-container')
  } else {
    getHotelsFilterableInfo = $('.wrapper.hotels-info-with-filtering').length === 1 ? $('.wrapper.hotels-info-with-filtering') : $('.block.hotel-list')
  }

  getHotelsFilterableInfo.data('hotels-filterable-info').map(function (hotel, index) {
    const data_hotels = hotel['hotels']
    for (let i = data_hotels.length - 1; i >= 0; i--) {
      all_hotels_ids.push(`h${data_hotels[i]}`)
    }
  })

  // Sacamos todos los datos de hoteles
  $.map(all_hotels_ids, function (val, index) {
    const obj = filterById(val, hotels_data.hotels[1])
    if (obj !== undefined) all_hotels_data.push(obj)
  })

  const product_impression_list =
    $('.horizontal-card.hotel').length > 0
      ? $('.horizontal-card.hotel').first().data('utag-eec').product_impression_list
      : [utag_data.page_section, utag_data.page_type, 'hotel list'].join('|')
  IB.utag_eec_events.generate_impression_product_list_object(all_hotels_data, product_impression_list)

  $.map(all_hotels_data, function (val, idx) {
    const hotel = val
    const hotel_id = val.id.split('h')[1]
    const hotel_code = hotel.data.hotel_code
    const hotel_lat = hotel.data.lat
    const hotel_lng = hotel.data.lng
    const json_utag_data_eec = JSON.parse(hotel.data.utag_data_eec)
    const hotel_gmaps_zoom = hotel.gmaps_zoom
    const has_coords = hotel_lat.length > 0 && hotel_lng.length > 0
    const hotel_not_available_message = $('#fb-results').length > 0 ? $(`.result-hotel[data-id=${hotel_id}] .not-available-message`).html() : ''
    let template =
      hotel_not_available_message == undefined || $('#fb-results').length == 0
        ? $('#hotel-info-box-template').html()
        : $('#hotel-info-box-template-no-availability').html()
    const hotel_location = hotel.data.city
    const hotel_url = hotel.data.url
    const hotel_title_parameterize = hotel.title.split(' ').join('-').toLowerCase()
    const hotel_name = hotel.title
    const hotel_category_number = hotel.data.hotel_category_number
    const hotel_image = hotel.data.images
    let hotel_price = IB.currencyForm.buildTagsFromPrice(hotel.data.price)
    const hotel_currency_prices = hotel.data.currency_prices
    const hotel_price_condition = hotel.data.price_condition_short_text
    const slide_template = []
    const hotel_list_length = $('.hotel-list').length
    let is_grand_emea
    let book_now_btn
    let info_box_template
    let template_options
    let pin
    let segmentations_html = ''
    let subsegmentations_html = ''
    json_utag_data_eec.product_impression_list = product_impression_list
    const utag_data_eec = JSON.stringify(json_utag_data_eec)
    /**
     * Segmentations: 'city' || 'heritage' || 'ocean'
     */
    $.each(hotel.data.segmentations, function (i, value) {
      if (value == null) {
        return segmentations_html
      }
      segmentations_html += `<li class='badge round uppercase-small ${value.class} not-hover'>${value.title}</li>`
    })

    /**
     * Subgmentations: 'Iberostar' || 'Grand'
     *
     * Do not render Iberostar's badge. Only grand's.
     */
    $.each(hotel.data.subsegmentations, function (i, value) {
      if (value == null || (value !== null && value.title !== 'Grand')) {
        return subsegmentations_html
      }
      subsegmentations_html += `<li class='badge round uppercase-small ${value.class} not-hover'>${value.title}</li>`
    })

    if (hotel.data.next_opening) return

    hotel_price = hotel_price.length == 0 ? hotel.data.price : hotel_price

    if (hotel_list_length) {
      const $this_hotel_card = $('.hotel-list').find(`[data-hotels="[${hotel.id.replace(/\D/g, '')}]"]`)
      book_now_btn = $this_hotel_card.find('.btn-primary').prop('outerHTML')
      if ($this_hotel_card.hasClass('grand-collection')) is_grand_emea = 'grand-collection'
      if (IB.currentDevice === 'mobile') {
        book_now_btn = $this_hotel_card.find('.secondary-btn').prop('outerHTML')
      }
    }

    for (let i = 0; i < hotel_image.length; i++) {
      if (!hotel_image[i].crops) hotel_image[i].crops = ['', '']
      slide_template.push(
        `<div class='swiper-slide'>
              <img class='infobox__image infobox-img' alt='${hotel_image[i].alt}' src='${hotel_image[i].crops[0]}' loading="lazy"/>
          </div>`)
    }

    if (IB.currentDevice === 'mobile') {
      template = $('#mobile-hotel-info-box-template').html()

      pin = 'hotel_not_selected'

      template_options = {
        hotel_code,
        hotel_id,
        hotel_slides: slide_template.join(''),
        hotel_url,
        hotel_name,
        hotel_category: hotel_category_number,
        hotel_price,
        hotel_currency_prices,
        hotel_price_condition,
        book_now_btn,
        grand_style: is_grand_emea,
        utag_data_eec,
        hotel_lat,
        hotel_lng,
        hotel_gmaps_zoom,
        hotel_title_parameterize,
        segmentations: segmentations_html,
        subsegmentations: subsegmentations_html,
        hotel_segment: $(`.js-dest-card[data-hotels="[${hotel_id}]"] .badge:not(.service)`).first().parent().html(),
        hotel_services: $(`.js-dest-card[data-hotels="[${hotel_id}]"] .info-box-services`).html(),
        has_hotel_services: $(`.js-dest-card[data-hotels="[${hotel_id}]"] .info-box-services`).length ? '' : 'hidden',
        hotel_location,
        hotel_opinion: $(`.js-dest-card[data-hotels="[${hotel_id}]"] .opinion-widget`).html(),
        hotel_book_btn_href: $(`.js-dest-card[data-hotels="[${hotel_id}]"] .js-book-btn`).attr('href'),
      }
    } else {
      pin = 'hotel_one'

      template_options = {
        hotel_id,
        hotel_slides: slide_template.join(''),
        hotel_location,
        hotel_url,
        hotel_name,
        hotel_code,
        hotel_category: hotel_category_number,
        hotel_price,
        hotel_currency_prices,
        hotel_price_condition,
        utag_data_eec,
        book_now_btn,
        grand_style: is_grand_emea,
        segmentations: segmentations_html,
        subsegmentations: subsegmentations_html,
        hotel_lat,
        hotel_lng,
        hotel_gmaps_zoom,
        hotel_title_parameterize,
      }
    }

    info_box_template = initTemplate(template, template_options)

    // $(info_box_template).prepend($('.card[data-hotels="[' + hotel_id + ']"] .badge:not(.service)').first().html());
    // Create location
    if (has_coords) {
      locations[val.id] = {
        pin,
        lat: hotel_lat,
        lng: hotel_lng,
        custom_data: {
          url: hotel_url,
        },
      }
      locations[val.id].iw = info_box_template
      locations[val.id].location = hotel_location
    }
  })
}

function startHotelsMapMobile() {
  $map = $('#hotels-filter-map')

  const text = $map.data('mobile-text')
  if ($($map).length != 0) {
    $map.wrap('<div id="hotels-filter-map-cnt" class="mobile-panel fastbooking-mobile-map"></div>').removeAttr('aria-labelledby')
    $('#hotels-filter-map-cnt').prepend(`<div class="menu-list-header t-h5-header">${text}</div>`).mobilepanel().attr({
      'aria-labelledby': 'hotels-filter-map-trigger hotels-filter-map-trigger-sticky',
      role: 'tabpanel',
    })
    $('#hotels-filter-map-cnt').append($('.loading-map'))
  }
  $('.hotel-map-link')
    .attr({
      href: '#hotels-filter-map-cnt',
      'aria-controls': 'hotels-filter-map-cnt',
    })
    .addClass('open-mobile-panel fastbooking-mobile-map')

  $('.hotel-list')
    .find('[data-filterable]')
    .find('.map')
    .each(function (index, el) {
      const $this = $(el)
      $this.removeAttr('data-fancymap')
      $this.addClass('open-mobile-panel one-hotel-map').attr({
        href: '#hotels-filter-map-cnt',
        'aria-controls': 'hotels-filter-map-cnt',
        role: 'tab',
      })
    })

  $(document)
    .on('click', '.one-hotel-map', function (event) {
      event.preventDefault()
      const $this = $(this)
      const ids = $this.closest('[data-filterable]').data('hotels')
      if ($this.hasClass('map-is-activated') ) {
        IB.hotels_filter_map.filterOneHotel(ids)
      } else {
        IB.hotels_filter_map.init($this, {
          type: 'single',
        })
      }
    })
    .on('click', '.info-box-mobile .s-close-fat', function (e) {
      e.preventDefault()
      $(this).closest('.info-box-mobile').removeClass('active')
      setTimeout(function () {
        $(this).closest('.info-box-mobile').remove()
      }, 300)
    })
    .on('click', '.mobile-panel.fastbooking-mobile-map .close-mobile-panel', function (e) {
      $('.info-box-mobile').removeClass('active').remove()
    })
}

function filterVisible() {
  if (hotels_map === undefined) return
  const visible_ids = []
  const filter_visible_markers = []

  // Sacamos los ids de todos los hoteles visibles
  if (!JSON.parse(localStorage.getItem('filter_hotel_ids'))) getCheckedFilters()
  const filterHotelIds = JSON.parse(localStorage.getItem('filter_hotel_ids'))

  if (filterHotelIds !== null && filterHotelIds.length) {
    for (let i = filterHotelIds.length - 1; i >= 0; i--) {
      visible_ids.push(`h${filterHotelIds[i]}`)
    }
  } else {
    const getHotelsFilterableInfo = $('.wrapper.hotels-info-with-filtering').length === 1 ? $('.wrapper.hotels-info-with-filtering') : $('.block.hotel-list')
    getHotelsFilterableInfo.data('hotels-filterable-info').map(function (hotel, index) {
      const data_hotels = hotel['hotels']
      for (let i = data_hotels.length - 1; i >= 0; i--) {
        visible_ids.push(`h${data_hotels[i]}`)
      }
    })
  }

  hotels_map.showMarkerGroup(visible_ids, true)

  $.map(visible_ids, function (val, index) {
    const m = filterMarkers(val, all_markers_array)
    if (m !== undefined) filter_visible_markers.push(m)
  })

  hotels_map.marker_cluster.clearMarkers()
  hotels_map.marker_cluster.addMarkers(filter_visible_markers)
}

function filterById(id, data) {
  const el = $.grep(data, function (n, i) {
    if (n != null) {
      return n.id === id
    }
  })
  if (el.length) {
    return el[0]
  }
}

function filterMarkers(id, data) {
  const el = $.grep(data, function (n, i) {
    if (n != null) {
      return n.idx === id
    }
  })
  if (el.length) {
    return el[0]
  }
}

function filterOneHotel(ids) {
  const h_ids = []
  const filter_markers = []

  for (let i = ids.length - 1; i >= 0; i--) {
    h_ids.push(`h${ids[i]}`)
  }

  hotels_map.showMarkerGroup(h_ids, true)

  $.map(h_ids, function (val, index) {
    filter_markers.push(filterMarkers(val, all_markers_array))
  })

  hotels_map.marker_cluster.clearMarkers()
  hotels_map.marker_cluster.addMarkers(filter_markers)
}

function updateInfoviewHTML(data_with_html) {
  if (IB.hotels_filter_map.hotelsMap === undefined) return // map not initialized yet
  const hotelIDs = Object.keys(data_with_html).map(hotel_id => hotel_id)

  hotelIDs.forEach(hotel_id => {
    if (IB.hotels_filter_map.hotelsMap.markers[`h${hotel_id}`] !== undefined) {
      const htmlParser = new DOMParser()
      let htmlFromString = htmlParser.parseFromString(IB.hotels_filter_map.hotelsMap.markers[`h${hotel_id}`].iw, 'text/html')
      if (IB.currentDevice !== 'mobile') {
        const domElement = htmlFromString.querySelector('.card.horizontal-card.hotel')
        const cardContainer = domElement.querySelector('.card-container')
        const callToActionContent = cardContainer.querySelector('.call-to-action')

        htmlFromString = htmlParser.parseFromString(data_with_html[hotel_id], 'text/html')
        const newCallToActionContent = htmlFromString.body.firstChild
        if (newCallToActionContent.classList.contains('call-to-action') && !newCallToActionContent.classList.contains('action')) {
          newCallToActionContent.classList.add('action')
        }

        if (callToActionContent) {
          callToActionContent.remove()
          cardContainer.appendChild(newCallToActionContent)
          IB.hotels_filter_map.hotelsMap.markers[`h${hotel_id}`].iw = domElement.outerHTML
        }
      } else {
        const infoBoxContainer = htmlFromString.querySelector('div.info-box-container')
        const callToActionContent = infoBoxContainer.querySelector('div.call-to-action.action')

        htmlFromString = htmlParser.parseFromString(data_with_html[hotel_id], 'text/html')
        const newCallToActionContent = htmlFromString.body.firstChild
        if (newCallToActionContent.classList.contains('call-to-action') && !newCallToActionContent.classList.contains('action')) {
          newCallToActionContent.classList.add('action')
        }

        if (callToActionContent) {
          callToActionContent.remove()
          infoBoxContainer.appendChild(newCallToActionContent)
          IB.hotels_filter_map.hotelsMap.markers[`h${hotel_id}`].iw = infoBoxContainer.outerHTML
        }
      }
    }
  })

  if (document.querySelector('.loading-map')) document.querySelector('.loading-map').style.display = 'none'
}

window.IB.hotels_filter_map = {
  init: initHotelsMapForFilerPage,
  startHotelsMapMobile,
  filterVisible,
  filterOneHotel,
}

if (IB.currentDevice === 'mobile') {
  startHotelsMapMobile()
} else {
  const show_map_tab = $('.hotel-list').data('show-map-tab')
  if (show_map_tab === 1) $('.tabs-menu > li a.hotel-map-link').trigger('click')
}
