import { readCookie } from '../../utils/cookie_manager'

const user_resident = readCookie('resident')
let currency = readCookie('currency')

if (typeof currency === 'undefined' || currency === null) {
  currency = 'DEF'
}

function isResident(resident_type) {
  return user_resident !== undefined && resident_type !== undefined && user_resident == resident_type
}

export function updatePricesInfo() {
  $.each($('.price-info'), function (index, element) {
    const $this = $(this)
    const base_price = parseInt($this.data('base-price'))
    const resident_price = parseInt($this.data('resident-price'))
    const resident_type = $this.data('resident-type')
    let final_price = parseInt($this.data('price'))

    if (
      isResident(resident_type) &&
      resident_price !== null &&
      typeof resident_price !== 'undefined' &&
      !isNaN(resident_price)
    ) {
      final_price = resident_price
    }
    if (final_price < base_price) {
      const $text = $($this.find('[data-now-text]')[0])
      const text = $text.data('now-text')
      $text.text(text)
      // Cambiamos el data para la ordenación
      $this.closest('div.card').attr('data-price', final_price)

      // Texto 'desde' en ofertas
      $this.find('p.text-before-price').hide()
    } else {
      $this.find('.price-before, [data-price-before]').hide()
    }

    // remove prices skeleton
    $this.closest('.call-to-action').find('.price-info-fake').remove()
  })
}
