//
// ORDER OPTIONS
// must match data-order-types attributes in order selector's HTML
//
export const ALPHABETICAL_ORDER_OPTIONS = ['alphabetical_order_up', 'alphabetical_order_down']

export const PRICE_ORDER_OPTIONS = ['ascending_price', 'descending_price']

export const DATE_ORDER_OPTIONS = ['ascending_date', 'descending_date']

export const RECOMMENDED_ORDER_OPTIONS = 'recommended'

export const HOTEL_WITHOUT_PRICE = 'hotel_ids_without_price'

export const OTHER_ORDER_OPTIONS = ['category', RECOMMENDED_ORDER_OPTIONS]

export const VALID_ORDER_VALUES =
  ALPHABETICAL_ORDER_OPTIONS + PRICE_ORDER_OPTIONS + DATE_ORDER_OPTIONS + OTHER_ORDER_OPTIONS

const AUTO_OFFER_LIST_PRESENT = document.querySelector('.automatic-offer-list')

export const DEFAULT_ORDER = RECOMMENDED_ORDER_OPTIONS

export const PAGINATION_CONFIG = {
  hotels_info_with_filtering: {
    action: 'ajax_contents/hotels_pagination',
    maxResourcesPerPage: 6,
    resourceParam: 'hotel_ids',
  },
  automatic_offer_list: {
    action: 'ajax_contents/crs_offers_pagination',
    maxResourcesPerPage: 7,
    resourceParam: 'crs_offer_ids',
  },
}
