import Modal from '../../shared/modal/modal'

export function openLoader() {
  Modal.show('wait-loading', {
    onShow: () => ScrollLock.on(),
    onClose: () => ScrollLock.off(),
  })
}

export function closeLoader() {
  Modal.close()
}

function init() {
  const loadingPage = IB.currentPageType.isNeedsLoaderPage()
  if (loadingPage) {
    openLoader()
  }
}

init()
