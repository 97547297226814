// TODO: First refactor of this file on 202307
/**
 * Filters
 *
 * Manage hotels info with filtering filters functionality.
 *  - Send the requests to the server with the params needed
 *  - Process the response, replacing current hotels with the HTML in the response's JSON
 */

import '../../../shared/filters_sidebar/filters_sidebar'

import _intersection from 'lodash/intersection'
import _union from 'lodash/union'

import { currentDevice } from '../../../core/utils/currentDevice'
import { closeLoader, openLoader } from '../../../elements/wait_loading/wait_loading'
import { updatePricesInfo } from '../../../shared/update_prices/update_prices'
import { fromStringToArr } from '../../../utils/from_string_to_array'
import { getLazyData } from '../../../utils/get_lazy_data'

import { DEFAULT_ORDER } from './pagination/pagination_constants'

const HOTELS_INFO_WITH_FILTERING_SELECTOR = '.js-hotels-info-with-filtering'
const LOCALSTORAGE_KEY = 'filter_hotel_ids'

export const UPDATE_FILTERS_EVENT = 'hotels-info-filter:update'
export const FILTERS_UPDATE_EVENT = 'filters:update'

let PaginationManager = null
let initialPageHotelIDs = null
let autoOfferList = null
let pagStatusText = null
let inResultsPage = null
let filterOff = false
let micePage = null

function handleAvailabilityFinished(e) {
  //Set hotels without prices to append at the end of results in order by price
  if (e.fromCRSPricesSync) {
    e.idsToAppend && PaginationManager.setIdsToAppend(e.idsToAppend)
  }
}

function handlePaginationMobileOrderByClick(event) {
  if (event.target && event.target.classList.contains('js-pagination-mobile-order-by')) {
    const previouslySelectedOpt = document.querySelector('.js-pagination-mobile-order-by[checked]')
    previouslySelectedOpt && previouslySelectedOpt.removeAttribute('checked')

    event.target.setAttribute('checked', true)
    document.querySelector('[data-mobile-panel] .bottom-actions').classList.remove('hidden')
  }
}

function handleApplyFiltersClick(e) {
  if (e.target && e.target.classList.contains('js-apply-filters')) {
    const orderType = $('#order-list input[type=radio]:checked').attr('value')
    PaginationManager.updateState({ order: orderType })
    fetchHotels()
  }
}

function handleTagClick(e) {
  if (e.target && e.target.classList.contains('tag')) {
    document.querySelector(`.filters-sidebar #${e.target.dataset.id}`).click()
  }
}

function handleFiltersUpdate(e) {
  fetchHotels()
  FilterSidebar.updateFiltersCounter()
}

export function init(PManager) {
  // PY04776-683 - 777523
  /**
   * Open fancybox modal after entering a booking page,
   * and thus block the user from checking filters before the request.
   * When the request ends, it will close as many modals as are open.
   */
  IB.currentPageType.isBookings() && openSpinner()

  PaginationManager = PManager
  inResultsPage = !!document.querySelector('#fb-results')
  initialPageHotelIDs = getAllHotelIDs()
  pagStatusText = document.querySelector('.pagination .status')
  // endpoint and parameter config
  autoOfferList = document.querySelector('.automatic-offer-list')
  micePage = document.querySelector('.results-search-meetings')

  if (!autoOfferList && !micePage) {
    // Update event
    // Trigered for pagination to restart with hotel without prices
    document.addEventListener('availability:finished', handleAvailabilityFinished)
  }

  //Stops init if hotels_info_with_filtering does not exist
  if (!document.querySelector('.hotels-info-with-filtering')) return

  if (currentDevice.isMobile || currentDevice.isLikeMobile) {
    document.addEventListener('click', handlePaginationMobileOrderByClick)

    if (document.querySelector('.filters-sidebar')) {
      document.querySelector('.filters-sidebar').addEventListener('click', clearFiltersMobile, { once: true })
    }
  }
  document.querySelectorAll('.filters-sidebar .with-counter input').forEach(el => {
    el.addEventListener('click', function (event) {
      if (el.parentElement.classList.contains('disabled')) {
        event.preventDefault()
        event.stopImmediatePropagation()
        return
      }

      if (checkBlockedFilter(el, event)) return

      if (currentDevice.isMobile || currentDevice.isLikeMobile) {
        const bottomActions = document.querySelector('[data-mobile-panel] .bottom-actions')

        if (!document.querySelectorAll('.filters-sidebar')[0].querySelectorAll('.checked')[0]) {
          filterOff = true
        }

        el.parentElement.classList.toggle('checked')
        el.classList.toggle('active')
        const hotelIDsToShow = getHotelIDsToShow()

        const filterType = el.closest('[data-filter-type]')?.dataset.filterType

        // not caching .bottom-actions in var because it is built via JS
        // and would not be available upfront
        if (hotelIDsToShow.length) {
          updateApplicableFilters(hotelIDsToShow, filterType)
          bottomActions?.classList.remove('hidden')
        } else {
          initialApplicableFilters()
          bottomActions?.classList.add('hidden')
        }
      } else {
        if (!el.checked) {
          // we are deactivating the filter
          el.parentElement.classList.toggle('checked')
          const tag = document.querySelector(`.filter-header [data-id="${el.id}"]`)
          tag && tag.remove()
        }

        setTimeout(() => {
          fetchHotels(el)
          FilterSidebar.updateFiltersCounter()
        }, 0) // waits for all active/inactive filter classes to be applied
      }
    })
  })

  $(document).on('click', '.clear-all', clearFilters)

  document.addEventListener('click', handleApplyFiltersClick)

  document.addEventListener('click', handleTagClick)

  /**
   * Update Filters state
   *
   * Used for filters that are applied upfront from params in URL
   * Should manage filter-specific task related to that:
   *  * Update applicable filters.
   *  * Adds tags.
   *
   */
  document.addEventListener(FILTERS_UPDATE_EVENT, handleFiltersUpdate)

  if (typeof IB === 'undefined') {
    window.IB = {}
  }
  if (typeof IB.filters === 'undefined') {
    window.IB.filters = {}
  }
  window.IB.filters.getHotelIDsToShow = getHotelIDsToShow
}

// Remove all event listeners
export function destroyEvents() {
  document.removeEventListener('availability:finished', handleAvailabilityFinished)
  document.removeEventListener('click', handlePaginationMobileOrderByClick)
  document.removeEventListener('click', handleApplyFiltersClick)
  $(document).off('click', '.clear-all', clearFilters)
  document.removeEventListener('click', handleTagClick)
  document.removeEventListener(FILTERS_UPDATE_EVENT, handleFiltersUpdate)
}

/**
 * Opens modal when user clicks a blocked filter
 */
function checkBlockedFilter(checkedFilter, event) {
  const allCheckedFilters = document.querySelectorAll('.filters-block .filters label.checked input')
  const $modal = document.querySelector('#deactivated-filter')
  checkedFilter.parentElement.classList.toggle('checked')
  let isBlocked = false

  if (!getHotelIDsToShow().length && allCheckedFilters.length > 1) {
    event.preventDefault()
    event.stopImmediatePropagation()
    $.fancybox.open($modal, {
      beforeShow() {
        ScrollLock.on()
      },
      beforeClose() {
        ScrollLock.off()
      },
    })
    isBlocked = true
  }
  checkedFilter.parentElement.classList.toggle('checked')

  return isBlocked
}

/**
 *
 * @returns An array with all hotel ids from "hotels-info-with-filtering" dataset
 */
function getAllHotelIDs() {
  let hotelIDs = []
  const hotels_info_block = document.querySelector('.hotels-info-with-filtering')

  try {
    if (hotels_info_block !== null) hotelIDs = fromStringToArr(hotels_info_block.dataset.allHotels)
  } catch (error) {
    console.log(`Errors: ${error}`)
  }

  return hotelIDs
}

/**
 * Builds the endpoint with the hotel IDs for the selected filter
 * using current active filters
 * and calls the function that sends the request.
 *
 * @param {HTMLNode | null}
 *    filterNode: The HTML node of the filter
 *    null if called from "clear all filters" button
 *
 */
function fetchHotels(filterNode) {
  if (filterNode && (!currentDevice.isMobile || !currentDevice.isLikeMobile)) {
    filterNode.classList.toggle('active')
  }

  const hotelIDsToShow = getHotelIDsToShow()
  const filterType = filterNode?.closest('[data-filter-type]')?.dataset.filterType
  if (hotelIDsToShow.length) {
    showAllFilters()
    updateApplicableFilters(hotelIDsToShow, filterType)
    if (!autoOfferList) {
      hidePagStatusText()
    }
  } else {
    hideAllFilters()
    initialApplicableFilters()
    if (!autoOfferList) {
      showPagStatusText()
    }
  }

  autoOfferList && updatePagStatusText(hotelIDsToShow)

  let hotelIDsToFetch = hotelIDsToShow.length ? hotelIDsToShow : initialPageHotelIDs

  if (inResultsPage && PaginationManager.state.order === DEFAULT_ORDER) {
    hotelIDsToFetch = PaginationManager.state.initialResourceIDs.filter(value => hotelIDsToFetch.includes(value))
  }
  if (inResultsPage && PaginationManager.state.order === 'descending_price') {
    hotelIDsToFetch = PaginationManager.state.initialResourceIDs
      .filter(value => hotelIDsToFetch.includes(value))
      .reverse()
  }
  PaginationManager.updateState({ ids: hotelIDsToFetch })
  if (!micePage) {
    fetchResults()
  } else if (!$('#hotels-filter-map').is(':hidden')) {
    IB.hotels_filter_map.filterVisible()
  }

  // Dispatch Event when filters change
  const updateFiltersEvent = new Event(UPDATE_FILTERS_EVENT)
  const hotelInfoWithFiltering = document.querySelector(HOTELS_INFO_WITH_FILTERING_SELECTOR)

  if (hotelInfoWithFiltering) {
    hotelInfoWithFiltering.dispatchEvent(updateFiltersEvent)
  }
}

/**
 * Clear filters in desktop/tablet
 */
export function clearFilters(e, updateFilters = true) {
  e && e.preventDefault()
  document.querySelector('.destination-index-tags')?.remove()

  if (updateFilters && (currentDevice.isMobile || currentDevice.isLikeMobile)) {
    document
      .querySelectorAll('.mobile-panel.opened form #order-list input[type=radio]')
      .forEach(radio => radio.removeAttribute('checked'))
    document.querySelector('.mobile-panel.opened form #order-list input[type=radio]#ascending_price_panel').checked =
      true
    const orderType = $('#order-list input[type=radio]:checked').attr('value')
    PaginationManager.updateState({ order: orderType })
    document.querySelector('.mobile-panel.opened .close-mobile-panel').click()
  }

  filterOff = false

  const allFiltersInputs = document.querySelectorAll('.filters-sidebar .with-counter input')
  allFiltersInputs.forEach(input => {
    input.classList.remove('active')
    input.parentElement.classList.remove('checked') // the parent label
    input.checked = false
  })

  updateFilters && fetchHotels()
}

/**
 * Clear filters in mobile
 */
function clearFiltersMobile(e) {
  // Evitamos duplicados de cargar filters en varios scripts
  setTimeout(() => {
    if (document.querySelector('.mobile-panel.filters-panel.js-filters-panel.right.ready.opened')) {
      if (document.querySelector('.filters-sidebar .checked') === null && filterOff) {
        filterOff = false
        document.querySelector('.filters-sidebar').removeEventListener('click', clearFiltersMobile)
        clearFilters(e)
      }
    }
    document.querySelector('.filters-sidebar').addEventListener('click', clearFiltersMobile, { once: true })
    FilterSidebar.updateFiltersCounter()
  }, 300)
}

/**
 * Hide all filters
 *
 */
function hideAllFilters() {
  $('.hotels-info-with-filtering .bottom-actions').addClass('hidden')
}

/**
 * Show all filters
 *
 */
function showAllFilters() {
  $('.hotels-info-with-filtering .bottom-actions').removeClass('hidden')
}

/**
 * Checks active filters and returns their IDs.
 *
 * @returns {array} hotelIDs: IDs from previously applied filters
 */
function getHotelIDsToShow() {
  const acumulativeFilters = getIDsFromIntersectionOfAcumulativeFilters()
  const restrictiveFilters = getIDsFromIntersectionOfRestrictiveFilters()
  const hotelIDsToShow =
    !!acumulativeFilters.length && !!restrictiveFilters.length
      ? _intersection(acumulativeFilters, restrictiveFilters)
      : _union(acumulativeFilters, restrictiveFilters)
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(hotelIDsToShow))

  return hotelIDsToShow
}

/**
 * Gets all unique acumulative types.
 */

function getAcumulativeTypes() {
  const allAcumulativeFilterActivated = [
    ...document.querySelectorAll('.filters-block.acumulatives .filters label.checked input'),
  ]
  let acumulativeTypes = []
  allAcumulativeFilterActivated.forEach(filter => {
    acumulativeTypes = _union(acumulativeTypes, [filter.dataset.filterType])
  })
  return acumulativeTypes
}

function getIDsFromIntersectionOfAcumulativeFilters() {
  const allCheckedAcumulativeFilters = document.querySelectorAll(
    '.filters-block.acumulatives .filters label.checked input'
  )
  const acumulativeTypes = getAcumulativeTypes()
  let acumulativeIntersection = []

  acumulativeTypes.forEach(acumulativeType => {
    let acumulation = []
    allCheckedAcumulativeFilters.forEach(filter => {
      const filterIds = getIDsFromFilterDataset(filter)
      const IDsFromFilter = Object.values(filterIds)[0]
      if (filter.dataset.filterType === acumulativeType) {
        acumulation = _union(acumulation, IDsFromFilter)
      }
    })
    acumulativeIntersection = acumulativeIntersection.length
      ? _intersection(acumulativeIntersection, acumulation)
      : _union(acumulativeIntersection, acumulation)
  })
  return acumulativeIntersection
}

function getIDsFromUnionOfAcumulativeFiltersByType() {
  const allCheckedAcumulativeFilters = document.querySelectorAll(
    '.filters-block.acumulatives .filters label.checked input'
  )
  const acumulativeTypes = getAcumulativeTypes()
  const acumulativeUnionByType = {}

  acumulativeTypes.forEach(acumulativeType => {
    let acumulation = []
    allCheckedAcumulativeFilters.forEach(filter => {
      const filterIds = getIDsFromFilterDataset(filter)
      const IDsFromFilter = Object.values(filterIds)[0]
      if (filter.dataset.filterType === acumulativeType) {
        acumulation = _union(acumulation, IDsFromFilter)
      }
    })
    acumulativeUnionByType[acumulativeType] = acumulation
  })
  return acumulativeUnionByType
}

function getIDsFromIntersectionOfRestrictiveFilters() {
  const allCheckedRestrictiveFilters = document.querySelectorAll(
    '.filters-block.restrictives .filters label.checked input'
  )
  let restrictiveIntersection = []

  allCheckedRestrictiveFilters.forEach(filter => {
    const filterIds = getIDsFromFilterDataset(filter)
    const IDsFromFilter = []

    for (const value of Object.values(filterIds)) {
      Array.prototype.push.apply(IDsFromFilter, value)
    }

    restrictiveIntersection = restrictiveIntersection.length
      ? _intersection(restrictiveIntersection, IDsFromFilter)
      : _union(restrictiveIntersection, IDsFromFilter)
  })
  return restrictiveIntersection
}

function getIDsFromFilterDataset(filter) {
  let filterIds = []
  try {
    filterIds = autoOfferList ? JSON.parse(filter.dataset.crsOffersByZone) : JSON.parse(filter.dataset.hotelsByZone)
  } catch (error) {
    console.log(`Errors: ${error}`)
  }
  return filterIds
}

/**
 * Iterate over the filters in page to check wether each one
 * of them is applicable or not based on the current selected.
 */
function updateApplicableFilters(hotelIDsToShow, filterType) {
  if (hotelIDsToShow.length) {
    const allFilters = document.querySelectorAll('.filters-sidebar .deactivables .with-counter input')
    const idsFromIntersectionOfRestrictiveFilters = getIDsFromIntersectionOfRestrictiveFilters()
    const idsFromIntersectionOfAcumulativeFilters = getIDsFromIntersectionOfAcumulativeFilters()
    const idsFromUnionOfAcumulativeFiltersByType = getIDsFromUnionOfAcumulativeFiltersByType()
    for (let index = 0; index < allFilters.length; index++) {
      const filter = allFilters[index]
      const hotelIDs = getHotelIDsByFilter(filter)
      const isAcumulativeFilter = !!filter.closest('.acumulatives')
      const filterIntersection = _intersection(hotelIDs, hotelIDsToShow).length
      if (
        isAcumulativeFilter &&
        filterType === filter.closest('[data-filter-type]').dataset.filterType &&
        idsFromIntersectionOfAcumulativeFilters.length
      )
        continue

      isAcumulativeFilter
        ? updateAcumulativeFilters(
            filter,
            hotelIDs,
            filterIntersection,
            idsFromUnionOfAcumulativeFiltersByType,
            idsFromIntersectionOfRestrictiveFilters
          )
        : updateGeneralFilters(filter, filterIntersection)
    }
  }
}

function updateAcumulativeFilters(
  filter,
  hotelIDs,
  filterIntersection,
  idsFromUnionOfAcumulativeFiltersByType,
  idsFromIntersectionOfRestrictiveFilters
) {
  const filterType = filter.dataset.filterType
  const _idsFromUnionOfAcumulativeFiltersByType = { ...idsFromUnionOfAcumulativeFiltersByType }
  const acumulativeTypes = getAcumulativeTypes()
  const restAccumulativeFiltersEmpty =
    !acumulativeTypes.length || (acumulativeTypes.length === 1 && acumulativeTypes[0] === filterType)
  const isChecked = filter.checked
  let filteredResults = filterIntersection

  if (!idsFromIntersectionOfRestrictiveFilters.length) {
    filteredResults = _intersection(hotelIDs, initialPageHotelIDs).length
  }

  // Get valid ids from another acumulative filters blocks
  _idsFromUnionOfAcumulativeFiltersByType[filterType] && delete _idsFromUnionOfAcumulativeFiltersByType[filterType]
  let currentAccumulativeFilterValidIDs = Object.values(_idsFromUnionOfAcumulativeFiltersByType).flat(1)
  // remove duplicates
  currentAccumulativeFilterValidIDs = [...new Set(currentAccumulativeFilterValidIDs)]

  const passesRestrictiveFilters =
    !idsFromIntersectionOfRestrictiveFilters.length ||
    hotelIDs.some(hotelId => idsFromIntersectionOfRestrictiveFilters.includes(hotelId))
  const passesOtherAccumulativeFilters =
    restAccumulativeFiltersEmpty || hotelIDs.some(hotelId => currentAccumulativeFilterValidIDs.includes(hotelId))

  const isEnabled = passesRestrictiveFilters && passesOtherAccumulativeFilters && filteredResults

  if (isEnabled) {
    filter.parentElement.classList.remove('disabled')

    // Could be enabled but not checked. In this case its necessary calculate how many ids passes all filters ignoring current accumulative type
    if (!isChecked && currentAccumulativeFilterValidIDs.length && !idsFromIntersectionOfRestrictiveFilters.length) {
      filteredResults = _intersection(hotelIDs, currentAccumulativeFilterValidIDs).length
    }
  } else {
    filter.parentElement.classList.add('disabled')
    filter.classList.remove('active')
    filter.parentElement.classList.remove('checked')

    const filterToRemove = document.querySelector(`.filter-header [data-id="${filter.id}"]`)
    if (filterToRemove !== null) filterToRemove.remove()
  }
  const $hotel_count = filter.parentElement.querySelector('.hotel-count')
  if ($hotel_count) {
    $hotel_count.classList.add('is-hidden')
    const counterEl = filter.parentElement.querySelector('.auto-count')
    counterEl.classList.remove('hidden')
    const counterText = filteredResults === 1 ? counterEl.dataset.one : counterEl.dataset.other
    counterEl.innerText = counterText.replace('{{count}}', filteredResults)
  }
}

function initialApplicableFilters() {
  const allFiltersInputs = document.querySelectorAll('.filters-sidebar .deactivables .with-counter input')

  allFiltersInputs.forEach(filterInput => {
    const $hotel_count = filterInput.parentElement.querySelector('.hotel-count')
    const $auto_count = filterInput.parentElement.querySelector('.auto-count')
    if ($auto_count) $auto_count.classList.add('hidden')
    if ($hotel_count) $hotel_count.classList.remove('is-hidden')
    filterInput.parentElement.classList.remove('disabled')
  })
}

function updateGeneralFilters(filter, filterIntersection) {
  if (filterIntersection) {
    filter.parentElement.classList.remove('disabled')
  } else {
    filter.parentElement.classList.add('disabled')

    if (filter.parentElement.classList.contains('checked')) {
      filter.classList.remove('active')
      filter.parentElement.classList.remove('checked')

      const filterToRemove = document.querySelector(`.filter-header [data-id="${filter.id}"]`)
      if (filterToRemove !== null) filterToRemove.remove()
    }
  }
  const $hotel_count = filter.parentElement.querySelector('.hotel-count')
  if ($hotel_count) {
    $hotel_count.classList.add('is-hidden')
    const counterEl = filter.parentElement.querySelector('.auto-count')
    counterEl.classList.remove('hidden')
    const counterText = filterIntersection === 1 ? counterEl.dataset.one : counterEl.dataset.other
    counterEl.innerText = counterText.replace('{{count}}', filterIntersection)
  }
}

function getHotelIDsByFilter(filter) {
  const hotelIds = []
  let hotelsByZoneObject

  if (autoOfferList) {
    hotelsByZoneObject = JSON.parse(filter.dataset.crsOffersByZone)
  } else {
    hotelsByZoneObject = filter.dataset.hotelsByZone ? JSON.parse(filter.dataset.hotelsByZone) : {}
  }

  for (const value of Object.values(hotelsByZoneObject)) {
    Array.prototype.push.apply(hotelIds, value)
  }
  return hotelIds
}

/**
 * Sends the request to the endpoint and executes the callback to update page
 */
function fetchResults() {
  openSpinner()
  togglePreventFurtherClicks()

  let offer_type = ''
  if ($('.automatic-offer-list').length) {
    offer_type = $('.automatic-offer-list').data('crsOfferType')
  }

  let offer_id = ''
  if (document.querySelector('html').dataset['pageType'] === 'offer') {
    offer_id = document.querySelector('html').dataset['resourceId']
  }

  const url = PaginationManager.getEndpoint({ for_bookings_page: inResultsPage, offer_type, offer_id })

  getLazyData(url, function (response, isResponseOK) {
    if (!isResponseOK) {
      closeSpinner()
      return
    }
    const data = JSON.parse(response)
    updatePage(data)
    inResultsPage && IB.booking_results.forceCRSprices()

    if (currentDevice.isMobile || currentDevice.isLikeMobile) {
      document.querySelector('.mobile-panel .close-mobile-panel').click()
    }

    if (response?.utag_add_product_impressions) {
      if (inResultsPage) {
        IB?.utag_eec_events?.utagLinkProductImpressionPaginationSearch(
          JSON.parse(response['utag_add_product_impressions']),
          PaginationManager.getCurrentPage()
        )
      } else {
        IB?.utag_eec_events?.utagLinkProductImpressionPagination(
          JSON.parse(response['utag_add_product_impressions']),
          PaginationManager.getCurrentPage()
        )
      }
    }

    // removes spinner and allow filters interactivity again
    closeSpinner()
    togglePreventFurtherClicks()

    if (autoOfferList) {
      //Event for reset counters in automatic offers
      const endFiltersEvent = new Event('appliedFilterResults')
      document.dispatchEvent(endFiltersEvent)
    }

    // add images in the response to observer,
    // initialize tooltips functionality.
    IB.lazyImg.addImgToObserver($('.hotels-info-with-filtering'))
    document.querySelectorAll('.hotel-list [data-tooltip]').forEach(tooltip => {
      IB.tooltip.init($(tooltip))
    })
    IB.currencyForm.forceUpdate()
    updatePricesInfo()
    IB.hotel_card_slider_mobile_gallery.init()
    // Si el mapa está visible/existe
    if (!$('#hotels-filter-map').is(':hidden')) IB.hotels_filter_map.filterVisible()
  })
}

/*
 * Fill the hotel list div with the HTML from the results json, updates elements' classes
 */
function updatePage(response) {
  if (autoOfferList) {
    document.querySelector('.hotel-list.hotel-list-with-filtering').innerHTML = response.html_replacements
  } else {
    document.querySelector('.hotels-info-with-filtering [data-block-list]').innerHTML = response.html_replacements
  }

  const paginationUpdateEvent = new Event('pagination:update')
  paginationUpdateEvent.hotelIDs = response.all_ids_hotel_by_order
  paginationUpdateEvent.page = PaginationManager.getCurrentPage()
  document.dispatchEvent(paginationUpdateEvent)

  const visibleHotels = JSON.parse(response.all_ids_hotel_by_order).length
  const total_counter = document.querySelector('.total-hotels-counter')
  const message_hotels_not_found = document.querySelector('.message-hotels-not-found')

  if (total_counter === null) {
    return false
  }

  if (visibleHotels) {
    message_hotels_not_found?.classList.add('hidden')
    total_counter.querySelector('.counter').textContent = visibleHotels
    total_counter.classList.remove('hidden')
  } else {
    message_hotels_not_found?.classList.remove('hidden')
    total_counter.classList.add('hidden')
  }
}

function openSpinner() {
  openLoader()
}

function closeSpinner() {
  closeLoader()
}

/**
 * Prevents sending more requests when already sent one
 * with a class with rule cursor-events: none
 */
function togglePreventFurtherClicks() {
  document.querySelector('.filters-sidebar').classList.toggle('loading')
}

/**
 * Hides pagination text "X - Y of Z Hotels in/for ..."
 *
 * Does not make sense when filtering. Implementing hiding it
 * when filtering for Black Friday release. May come back.
 */
function hidePagStatusText() {
  if (pagStatusText) pagStatusText.classList.add('invisible')
}

function showPagStatusText() {
  if (pagStatusText) pagStatusText.classList.remove('invisible')
}

function updatePagStatusText(selectedHotels) {
  const counter = document.querySelector('.js-pagination-total-hotels')
  if (counter) {
    counter.innerText = selectedHotels.length === 0 ? initialPageHotelIDs.length : selectedHotels.length
  }
}

export function getCheckedFilters() {
  window.IB.filters.getHotelIDsToShow()
}
